#form-file-upload {
	height: 16rem;
	width: 28rem;
	max-width: 100%;
	text-align: center;
	position: relative;
	margin-top: auto;
	border-right: 10px solid #78c4dc;  
	border-top: 10px solid #78c4dc; 
	padding: 10px 
  }
  
  #input-file-upload {
	display: none;
  }
  
  #label-file-upload {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-width: 4px;
	border-radius: 1rem;
	border-style: dashed;
	border-color: #FF8059;
	background-color: #f8fafc;
  }
  
  #label-file-upload.drag-active {
	background-color: #e3f1f7; /* Light blue background for more visual contrast */
  }
  
  #label-file-upload.loading {
    opacity: 0.5;
    cursor: not-allowed;
  }

  #label-file-upload.loading.drag-active {
	background-color: rgb(255, 172, 172);
  }

  .upload-button {
	cursor: pointer;
	padding: 0.25rem;
	font-size: 1rem;
	border: none;
	font-family: 'Oswald', sans-serif;
	background-color: transparent;
  }
  
  .upload-button:hover {
	text-decoration-line: underline;
  }
  
  #drag-file-element {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 1rem;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
  }