.settings-row {
	background-color: #f2f8fa;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0.5rem; /* Assuming the base font size is 16px and 1rem = 16px, so 6 * 4px = 24px */
}

.group-selection-button {
	font-size: 1rem !important;
	text-transform: none !important;
	width: 200px;
}

.group-selection-button.MuiButton-contained {
	color: white;
}

.reset-button {
	margin-right: 2rem !important;
	color: white !important;
}

.save-preset-button,
.save-default-button {
	margin-right: 1rem !important;
	color: white !important;
}

.default-settings-button {
	background: #ff8059 !important;
	color: white;
}

.default-settings-button:hover {
	background: #e46640 !important;
}

/* Don't display delete icon in textfield of custom preset drop down */
.custom-select-field .MuiSelect-select > .MuiButtonBase-root {
	display: none !important;
}
