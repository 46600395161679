.menu-nav, .menu-nav a{
	border-radius: 10px;
}

.menu-nav a {
	display: inline-block;
	padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 32px;
    padding-right: 32px;
}

.menu-nav:hover {
	background-color: transparent!important;
}

a:link, a:visited {
	color: unset;
}

a:hover,
a.active, 
a.active .menu-nav-icon svg  {
	color: white!important;
	background-color: #34859E!important;
	transition-duration: 0.5s!important;
}

a:hover .menu-nav-icon svg {
	color: white!important;
	background-color: transparent!important;
}

.menu-nav-icon {
	padding: 0!important;
	padding-right: 2px!important;
}

.menu-nav-icon svg {
	font-size: 28px;
}

#bug-report-icon,  #bug-report-icon:hover {
	color: unset;
	background-color: transparent!important;
}