body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	min-height: 100vh;
    border-left: 10px solid #78c4dc;
    border-right: 10px solid #78c4dc;  
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body:before, body:after {
    content: "";
    position: fixed;
    background: #78c4dc;
    left: 0;
    right: 0;
    height: 10px;
}

body:before {
    top: 0;
}

body:after {
    bottom: 0;
	z-index: 1;
}


/* Global scrollbar styles for WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background: white;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(220, 220, 220);
    border-radius: 4px;
    border: 2px solid white;
}

/* Global scrollbar styles for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgb(220, 220, 220) white;
}


/*  loading logo animation */
@keyframes pulse {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.2);
	}
	100% {
	  transform: scale(1);
	}
  }
  
  .pulse {
	animation: pulse 2s ease-in-out infinite;
  }

  
.spinner {
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    height: '100vh';
}

.logo {
    width: 5rem; /* Adjust size as needed */
    animation: pulse 2s ease-in-out infinite;
}