/* Remove any dot-related styling */
.fc-daygrid-event-dot {
	display: none !important;
}

/* Remove any padding and ensure the anchor occupies the full bloa ck */
a.fc-daygrid-event.fc-event {
	padding: 0 !important; /* Remove padding */
}

/* Set a fixed height for the day cell to accommodate exactly three events */
.fc-daygrid-day-frame {
	min-height: 120px; /* Adjust this value based on the height of your events */
	max-height: 120px;
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

/* Target FullCalendar header buttons */
.fc .fc-button-active {
	outline: none !important;
	box-shadow: none !important;
}

/* Optionally, remove focus border when clicked or focused */
.fc .fc-button:focus,
.fc .fc-button:active {
	outline: none;
	box-shadow: none;
}

/* Main container for events in the day view */
.event-multi-box {
	display: flex;
	flex-direction: column;
	height: 100%; /* Fills the entire parent container */
	width: 100%; /* Fills the full width */
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

/* Header row containing event info and fee estimate */
.event-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* Left-aligned start time, surgeon name, and hospital name */
.event-info {
	flex: 1; /* Takes up remaining space on the left */
	text-align: left;
}

/* Right-aligned session fee estimate */
.session-fee-estimate {
	text-align: right;
	font-weight: bold;
	color: #fff; /* Adjust for readability on dark background */
}

/* Each individual event box with margin for spacing */
.event-box {
	flex: 1; /* Each box takes an equal share of space */
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(58, 58, 58, 0.5);
	color: white; /* Text color for readability */
	border-radius: 5px; /* Rounded corners for a cleaner look */
	margin: 4px; /* Space around each event box */
	box-sizing: border-box;
	width: calc(100% - 8px); /* Adjust width to account for horizontal margin */
}
